import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import moment from "moment";
import { EMPTY_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA } from "presentation/constant/ManualCharge/ManualChargeHeaderSearchCriteria";
import { useManualChargeHeaderMaintenanceVM } from "presentation/hook/ManualCharge/useManualChargeHeaderMaintenanceVM";
import { useManualChargeHeaderMaintenanceTracked } from "presentation/store/ManualCharge/ManualChargeHeaderMaintenanceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import ManualChargeHeaderSearchPanel from "./LeftPanel/ManualChargeHeaderSearchPanel";
import ManualChargeHeaderTitleBar from "./ManualChargeHeaderTitleBar";
import ManualChargeHeaderTablePanel from "./Table/ManualChargeHeaderTablePanel";


const ManualChargeHeaderMaintenance:React.FC = () => {
    const [ manualChargeHeaderState ] = useManualChargeHeaderMaintenanceTracked();
    const { isShowCriteriaPanel, isBackFromDetail, searchCriteria ,isAllowAutoSearch} = manualChargeHeaderState;
    const manualChargeHeaderVM = useManualChargeHeaderMaintenanceVM();
    const [isLoading, setIsLoading] = useState(true);

    
    useEffect(() => {
        const initialScreen = async() => {   
            try {         
                const results = await Promise.allSettled([
                    manualChargeHeaderVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {
                        console.log(`Load charge details successful!`);
                      } else if (index === 1 && result.status === 'rejected') {
                        console.error(`Load dropdown options successful!`);
                      }
                })
            } catch(error) {
            }
        }

        
        isAllowAutoSearch && initialScreen().then((data)=>{
            let newSearchCriteria = null;
            if(isBackFromDetail){
                newSearchCriteria = {...searchCriteria}
            }else{
                newSearchCriteria = { 
                    ...EMPTY_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA, 
                    createdDateFrom: moment().subtract(10, "days").startOf("day").toDate(), 
                    createdDateTo: moment().endOf("day").toDate(),
                };
            }
            manualChargeHeaderVM.onSearch(newSearchCriteria,EMPTY_SEARCH_MODE_ENTITY)
        }).then((data) => {            
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
    }, [isAllowAutoSearch, isBackFromDetail, manualChargeHeaderVM, searchCriteria])


    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <>
        <div className={`main-comp-wrapper${isShowCriteriaPanel ? '' : ' im-hide-side-form-draggable'}`}>
           <ManualChargeHeaderTitleBar/>

            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowCriteriaPanel?"25%":"0%"}
                rightSectionWidth={isShowCriteriaPanel?"75%":"100%"}
                leftChildren={<ManualChargeHeaderSearchPanel/>}
                rightChildren={<ManualChargeHeaderTablePanel/>} 
                />
        </div>
    </>
}

export default memo(ManualChargeHeaderMaintenance);