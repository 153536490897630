import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_MAN_CHARGE_HDR_COL_DEF, transferRowData } from "presentation/constant/ManualCharge/ManChargeHdrColumnDefinition";
import { useManualChargeHeaderMaintenanceVM } from "presentation/hook/ManualCharge/useManualChargeHeaderMaintenanceVM";
import { useManualChargeHeaderMaintenanceTracked } from "presentation/store/ManualCharge/ManualChargeHeaderMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


const ManualChargeHeaderTablePanel:React.FC = () => {
    const [ manualChargeHeaderState ] = useManualChargeHeaderMaintenanceTracked();
    const manualChargeHeaderVM = useManualChargeHeaderMaintenanceVM();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    let gridRef: any = useRef();
    
    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_MAN_CHARGE_HDR_COL_DEF.slice());
        
        if (!manualChargeHeaderState.selectedManualChargeHeaderRows ||
            manualChargeHeaderState.selectedManualChargeHeaderRows.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
    })


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        manualChargeHeaderVM.updateSelectedManualChargeHeaders(selectedRows);
      }, [manualChargeHeaderVM])
    
    const memoManualChargeHeaderTable = useMemo(() => {
        return (
        <>            
            <HPHTable
                id='manual-charge-header-table'
                isNewColumnSetting={true}
                columns={INITIAL_MAN_CHARGE_HDR_COL_DEF.slice()}
                // headerLabel={'Manual Charge'}
                data={transferRowData(manualChargeHeaderState.manualChargeHeaders)||[]}
                showPaginator={false}
                editable={false}
                showHeaderIcons={true}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                rowSelection={"multiple"}
                onSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any) => {manualChargeHeaderVM.onDetail(e.data)}}
                gridHeight="customHeight"
                customHeight="calc(100vh - 125px)" 
                ref={gridRef}
            />
        </>
        );
    },[handleSelectionChange, manualChargeHeaderState.manualChargeHeaders, manualChargeHeaderVM])

    return <><TableWrapper>{memoManualChargeHeaderTable}</TableWrapper></>;
}

export default memo(ManualChargeHeaderTablePanel);
