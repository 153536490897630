import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ManualChargeHeaderMaintenanceProvider } from "presentation/store/ManualCharge/ManualChargeHeaderMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { ManChargeMDView } from "presentation/view/section/ManualCharge/ManChargeMDView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const ManualChargeContMain = () => {
    return <ANAInfoWrapper permission={Permission.MANUAL_CHARGE}>
        <MessageBarWrapper>
            <ManualChargeHeaderMaintenanceProvider>
                <GridStyles/>
                <ManChargeMDView/>
            </ManualChargeHeaderMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default ManualChargeContMain;