import { useManualChargeHeaderMaintenanceTracked } from "presentation/store/ManualCharge/ManualChargeHeaderMaintenanceProvider";
import ManualChargeDetailContMain from "presentation/view/container/ManualCharge/ManualChargeDetailContMain";
import React from "react";
import ManualChargeHeaderMaintenance from "./ManualChargeHeaderMaintenance";

export const ManChargeMDView: React.FC = () => {
     const [manHdrState] = useManualChargeHeaderMaintenanceTracked();
     const isShowDetail = manHdrState.isShowDetail;
   return <>
        {!isShowDetail 
            && <div className="main-comp-wrapper" >
            <ManualChargeHeaderMaintenance/>
        </div>}
    {
        isShowDetail && <div className="main-comp-wrapper" ><ManualChargeDetailContMain/> </div>
    } 

    </>
}
